<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { getAllUser } from "@/services/api/user";
import {userMethods} from "@/state/helpers.js"

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Advanced Table",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      tableData: [],
      title: "Advanced Table",
      items: [
        {
          text: "Tables",
          href: "/",
        },
        {
          text: "Advanced",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "fullname", sortable: false, label: "Name" },
        { key: "nomadic", sortable: false, label: "Nomad" },
        { key: "Contact", sortable: false },
        { key: "createdAt", sortable: false },
        { key: "updatedAt", sortable: false },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  created() {
    this.getNormalUsers();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    ...userMethods,
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getNormalUsers() {
      // this.dataLoading = true;
      this.tableData = [];
      getAllUser({ page: 1, perPage: 10, role: "Normal" })
        .then((res) => {
          console.log(res);
          this.tableData = res.data.users;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.dataLoading = false;
          console.log("Loaded");
        });
    },
    pageChange(val) {
      this.currentPage = val;
      this.getNormalUsers();
    },
    perPageChange() {
      this.getNormalUsers();
    },
    selectedUser(item) {
      console.log(item)
      this.selectNormalUser(item);
      this.$router.push({ name: "Normal Users details" });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                      @change="perPageChange"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :busy="dataLoading"
                @row-clicked="selectedUser"
                :hover="true"
              >
                <template #cell(following)="data">
                  {{ data.item.following.length }}
                </template>
                <template #cell(followers)="data">
                  {{ data.item.followers.length }}
                </template>
                <template #cell(contact)="data">
                  <strong>Email : </strong> {{ data.item.email }} <br />
                  <strong>Mobile : </strong> {{ data.item.phone_no }}
                </template>
                <template #cell(nomadic)="data">
                  <strong>ID: </strong>{{ data.item.nomadic_id }} <br />
                  <strong>Challenge: </strong>{{ data.item.challenge }} <br />
                  <strong>Nomadic Type: </strong>{{ data.item.nomad_type }}
                  <br />
                  <strong>Nomadic Level: </strong>{{ data.item.nomad_level }}
                  <br />
                </template>
                <template #cell(createdAt)="data">
                  {{ moment(data.item.createdAt).format("LLL") }}
                </template>
                <template #cell(updatedAt)="data">
                  {{ moment(data.item.updatedAt).format("LLL") }}
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      @change="pageChange"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>